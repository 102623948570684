<template>
    <div class="flex employee-details">
        <aside class="card menu self-start">
            <ul class="py-4">
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'general' }">
                    <router-link
                        :to="{ name: 'manager_details_general', params: { uuid: managerUuid }}"
                        class="flex py-3 items-center"
                        :class="{ 'active': activeMenu === 'general' }"
                    >
                        <fa-icon :icon="['fas', 'info']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('manager_details.general') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'employees' }">
                    <router-link
                        :to="{ name: 'manager_details_employees', params: { uuid: managerUuid }}"
                        class="flex py-3 items-center"
                        :class="{ 'active': activeMenu === 'employees' }"
                    >
                        <fa-icon :icon="['fas', 'user-cog']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('manager_details.employees') }}
                        </p>
                    </router-link>
                </li>
            </ul>
        </aside>
        <transition mode="out-in">
            <router-view @activeSubpage="changeActiveMenu" />
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            managerUuid: this.$route.params.uuid,
            name:        '',
            surname:     '',
            activeMenu:  'general',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('managers.manager_details'));
        this.$store.commit('setActiveMenuItem', '4-1');
    },

    methods: {
        changeActiveMenu(activeMenu) {
            this.activeMenu = activeMenu;
        },
    },
};
</script>

<style scoped>
    .menu {
        width: 350px;
        margin-right: 15px;
    }

    .active {
        @apply bg-gray-200 px-4 py-3 rounded relative;
        transition: 250ms ease background-color, 250ms ease color;
    }

    .active svg{
        color: #f26364 !important;
    }

    .active p {
        @apply font-semibold;
        color: #f26364 !important;
    }
</style>
